import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Collapse,
  Container,
  Grid,
  IconButton,
  Typography,
} from "@material-ui/core";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { auth } from "../../firebase";
import authentication from "../../services/authentication";
import Sentiment from "sentiment";
import { ExpandLess, ExpandMore } from "@material-ui/icons";

const endPoints = {
  // hot: "https://www.reddit.com/r/wallstreetbets/hot.json?limit=100",
  topHour: "https://www.reddit.com/r/wallstreetbets/top.json?t=hour&limit=100",
  topDay: "https://www.reddit.com/r/wallstreetbets/top.json?t=day&limit=100",
  // topWeek:
  //   "https://www.reddit.com/r/wallstreetbets/top.json?t=week&limit=100",
};

const options = {
  extras: {
    "🚀": 4,
    "🚀🚀": 4.5,
    "🚀🚀🚀": 5,
    "🚀🚀🚀🚀": 5.5,
    "🚀🚀🚀🚀🚀": 6,
    "🚀🚀🚀🚀🚀🚀": 6.5,
    "🚀🚀🚀🚀🚀🚀🚀": 7,
    "💎": 4,
    "💎💎": 4.5,
    "💎💎💎": 5,
    "💎💎💎💎": 5.5,
    "💎💎💎💎💎": 6,
  },
};
const sentiment = new Sentiment();

const StockCard = ({ stock, metrics }) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <Card>
      <CardHeader
        title={stock}
        action={
          <IconButton aria-label="expand">
            {expanded ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        }
        onClick={() => setExpanded(!expanded)}
        style={{ paddingBottom: 0 }}
      ></CardHeader>
      <CardContent>
        mentions: {metrics.mentionsCount}
        <br />
        flairs:{" "}
        {Object.entries(metrics.flairs || {})
          .map(([flair, count]) => `${flair}[${count}]`)
          .join(", ")}
        <br />
        sentiment: {metrics.sentiment}
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Box>
            {metrics.mentionsTexts.map(([text, url]) => (
              <Box p={1} borderBottom={1}>
                <a href={url} target="_blank" rel="noreferrer">
                  {text}
                </a>
              </Box>
            ))}
          </Box>
        </Collapse>
      </CardContent>
    </Card>
  );
};

const HomePage = ({ history, openSnackbar, user }) => {
  const [data, setData] = useState({ hot: null, topDay: null });
  const [stockMetrics, setStockMetrics] = useState({});
  const [loading, setLoading] = useState(false);
  const signInWithEmailLink = () => {
    if (user) return;
    const emailLink = window.location.href;
    if (!emailLink) return;
    if (auth.isSignInWithEmailLink(emailLink)) {
      let emailAddress = localStorage.getItem("emailAddress");
      if (!emailAddress) {
        history.push("/");
        return;
      }
      authentication
        .signInWithEmailLink(emailAddress, emailLink)
        .then((value) => {
          const user = value.user;
          const displayName = user.displayName;
          const emailAddress = user.email;

          openSnackbar(`Signed in as ${displayName || emailAddress}`);
        })
        .catch((reason) => {
          const code = reason.code;
          const message = reason.message;
          switch (code) {
            case "auth/expired-action-code":
            case "auth/invalid-email":
            case "auth/user-disabled":
              openSnackbar(message);
              break;
            default:
              openSnackbar(message);
              return;
          }
        })
        .finally(() => {
          history.push("/");
        });
    }
  };

  const getData = useCallback(async () => {
    Object.entries(endPoints).forEach(async ([k, v]) => {
      setLoading(true);
      const res = await fetch(v);
      if (res.status === 200) {
        const resJson = await res.json();
        setData((prevData) => ({ ...prevData, [k]: resJson.data.children }));
      } else {
        console.error(res);
      }
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    // signInWithEmailLink();
    getData();
  }, [getData]);

  const keys = {
    created: "",
    gilded: "",
    is_gallery: "",
    link_flair_text: "",
    num_comments: "",
    permalink: "",
    post_hint: "",
    score: "",
    selftext: "",
    total_awards_received: "",
    upvote_ratio: "",
    url: "",
  };

  // need bull or bear sentiment

  const mentionedStocks = useCallback((post) => {
    const stocks = [
      "NRZ",
      "BABA",
      "AMZN",
      "MSFT",
      "PLUG",
      "ZNGA",
      "SPCE",
      "GOOGL",
      "GM",
      "TXMD",
      "FB",
      "NKE",
      "PTON",
      "V",
      "SNE",
      "UBER",
      "CRM",
      "WMT",
      "AZN",
      "PYPL",
      "NVDA",
      "DIS",
      "APHA",
      "KO",
      "SIRI",
      "GE",
      "AAPL",
      "LI",
      "ET",
      "LUV",
      "SNAP",
      "JNJ",
      "XPEV",
      "NFLX",
      "DKNG",
      "NIO",
      "PENN",
      "AMD",
      "WKHS",
      "SQ",
      "NOK",
      "BAC",
      "DAL",
      "MRNA",
      // "SAVE",
      "WFC",
      "SBUX",
      "BA",
      "OGI",
      "UAL",
      "ABNB",
      "NKLA",
      "ZM",
      "KOS",
      "MRO",
      "TSLA",
      "PFE",
      "NCLH",
      "RCL",
      "JBLU",
      "XOM",
      "TWTR",
      "INTC",
      "T",
      "INO",
      "CRON",
      "F",
      "TLRY",
      "CGC",
      "AAL",
      "GPRO",
      "FCEL",
      "MGM",
      "MFA",
      "PLTR",
      "GME",
      "BB",
      "HEXO",
      "ACB",
      "CCL",
      "SNDL",
      "AMC",
      "CCIV",
      "ZOM",
      "VOO",
      "IDEX",
      "BNGO",
      "SPY",
      "PSEC",
      "GNUS",
      "ARKK",
      "NNDM",
      "CPRX",
      "IVR",
      "GUSH",
      "GEVO",
      "BLNK",
      "OCGN",
      "RIOT",
    ];

    const text = `${post.title} ${post.selftext}`;

    const mentions = {};

    stocks.forEach((stock) => {
      // Lookbehind doesn't work on safari, so capture with lookbehind character and filter
      // const re = new RegExp(`\\b(?<!['’;=?&])${stock}\\b`, "gi");
      const re = new RegExp(`\\b(['’;=?&]?${stock})\\b`, "gi");
      const count = (text.match(re) || []).filter(
        (match) => !match.match(/['’;=?&]/g)
      ).length;
      if (count > 0) {
        const textEmojiSpaced = text.replace(/([🚀💎🤲])/gu, " $1 ");
        const {
          positive,
          negative,
          words,
          tokens,
          ...textSentiment
        } = sentiment.analyze(textEmojiSpaced);
        if (!mentions[stock])
          mentions[stock] = { mentionsTexts: [], sentiment: 0 };
        mentions[stock].mentionsTexts.push([
          `${text}${JSON.stringify({ textSentiment })}`,
          `https://www.reddit.com${post.permalink}`,
        ]);
        mentions[stock].sentiment += textSentiment.comparative;
      }
    });
    return mentions;
  }, []);

  useEffect(() => {
    const stockData = {};
    data?.topDay?.forEach(({ data: post }) => {
      const postMentions = mentionedStocks(post);
      if (Object.keys(postMentions).length > 0) {
        Object.entries(postMentions).forEach(
          ([stock, { mentionsTexts, sentiment }]) => {
            if (mentionsTexts.length > 0) {
              if (!stockData[stock])
                stockData[stock] = {
                  mentionsCount: 0,
                  mentionsTexts: [],
                  flairs: {},
                  sentiment: 0,
                };
              stockData[stock].mentionsCount =
                stockData[stock].mentionsCount + 1;
              stockData[stock].mentionsTexts.push(...mentionsTexts);
              if (post.link_flair_text) {
                if (!stockData[stock].flairs)
                  stockData[stock].flairs = {
                    ...stockData[stock].flairs,
                    [post.link_flair_text]: 0,
                  };
                stockData[stock].flairs[post.link_flair_text] =
                  (stockData[stock].flairs[post.link_flair_text] || 0) + 1;
              }
              stockData[stock].sentiment += sentiment;
            }
          }
        );
      }
    });
    setStockMetrics(stockData);
  }, [data, mentionedStocks]);

  console.log("stockMetrics", stockMetrics);

  return (
    <Container>
      <Box p={1} display="flex" justifyContent="center">
        <Typography variant="h4">
          Most mentioned stocks on r/wsb today
        </Typography>
      </Box>
      {loading ? (
        <Box display="flex" justifyContent="center" m={16}>
          <CircularProgress color="primary" />
        </Box>
      ) : (
        <Grid container spacing={2}>
          {Object.entries(stockMetrics)
            .sort((a, b) => {
              return b.mentionsCount - a.mentionsCount;
            })
            .map(([stock, metrics]) => (
              <Grid item xs={12} key={stock}>
                <StockCard stock={stock} metrics={metrics} />
              </Grid>
            ))}
        </Grid>
      )}
    </Container>
  );

  // return (
  //   <Grid container spacing={2}>
  //     {data?.topDay?.map(({ data: post }) => (
  //       <Grid item xs={12} key={post.name}>
  //         <Card>
  //           <CardHeader title={post.title}></CardHeader>
  //           <CardContent>
  //             flair: [{post.link_flair_text}] guilds: {post.guilded}
  //             awards: {post.total_awards_received}
  //             upvote_ratio: {post.upvote_ratio}
  //             text: {post.selftext}
  //             {post.url && <img src={post.url} width="50%" />}
  //           </CardContent>
  //         </Card>
  //       </Grid>
  //     ))}
  //   </Grid>
  // );
  // return user ? (
  //   <EmptyState
  //     image={<CabinIllustration />}
  //     title="Home"
  //     description="This is the home page. You can edit it from HomePage.js."
  //   />
  // ) : (
  //   <EmptyState
  //     image={<InsertBlockIllustration />}
  //     title="RMUIF"
  //     description="Supercharged version of Create React App with all the bells and whistles."
  //   />
  // );
};

HomePage.propTypes = {
  user: PropTypes.object,
};

export default withRouter(HomePage);
